import { GraduationCap, ContactRound } from "lucide-react";
import { DiscordLogo } from "@fefyi/ui/astro/icons/discord-logo";
import { Tile } from "./tile";

export const SubnavMore = () => (
  <>
    <div className="grid gap-2 md:grid-cols-[repeat(3,160px)] lg:grid-cols-[repeat(3,250px)]">
      <Tile
        title="Mentorship"
        subtitle="Skip all the rabbit holes"
        icon={GraduationCap}
        href="/mentorships"
      />
      <Tile
        title="Discord"
        subtitle="Join our community — part of PRO"
        icon={DiscordLogo}
        href="/discord"
      />
      <Tile
        title="About"
        subtitle="My credibility"
        icon={ContactRound}
        href="/about"
      />
    </div>
  </>
);
