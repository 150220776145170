type Props = {
  title: string;
  subtitle: string;
  icon: React.ComponentType<{ className: string }>;
  href: string;
};

export const Tile = ({ title, subtitle, icon: Icon, href }: Props) => (
  <a
    href={href}
    className="group/anchor md:hover:bg-background-contrast md:focus-visible:bg-background-contrast md:bg-background-contrast flex flex-col rounded-2xl text-lg text-white outline-none transition-colors md:p-5 lg:text-xl"
  >
    <Icon className="relative mb-auto block h-8 w-8 transition-transform duration-700 group-hover/anchor:scale-125 group-focus-visible/anchor:scale-125 max-md:hidden" />

    <span className="block font-bold leading-tight md:mt-3">{title}</span>
    <span className="block text-[12px] leading-snug max-md:hidden">
      {subtitle}
    </span>
  </a>
);
