import { Button } from "@fefyi/ui/button";
import { ChevronDown, Menu, X } from "lucide-react";
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { twMerge } from "tailwind-merge";
import { SubnavCourses } from "./subnav-courses";
import { SubnavRecipes } from "./subnav-recipes";
import { AnimatePresence, motion, type Variants } from "framer-motion";
import { SubnavMore } from "./subnav-more";
import { LogoIcon } from "@fefyi/ui/logo";
import { useSound } from "@fefyi/ui/utils/use-sound";
import { create } from "zustand";
import { useWindowSize } from "~/utils/use-window-size";

const useDirectionStore = create<{
  direction: "left" | "right" | null;
  setDirection: (direction: "left" | "right") => void;
}>((set) => ({
  direction: null,
  setDirection: (direction: "left" | "right") => set({ direction }),
}));

type Props = {
  children: React.ReactNode;
  isPro?: boolean;
  activePath: string;
};

const items = ["videos", "recipes", "courses", "more"] as const;
type Items = (typeof items)[number];

type ItemProps = {
  children?: React.ReactNode;
  id: Items;
  href?: string;
  title: string;
  activePath?: string;
  isMobile: boolean;
};

const MenuItem = ({
  children,
  href,
  title,
  activePath,
  isMobile,
}: ItemProps) => {
  const direction = useDirectionStore((state) => state.direction);
  const [isSubnavVisible, setIsSubnavVisible] = useState(false);
  const [click] = useSound("/sfx/click.mp3");
  const hasSubnav = !!children;
  const subnavRendered = useMemo(
    () => hasSubnav && (isSubnavVisible || isMobile),
    [hasSubnav, isSubnavVisible, isMobile],
  );

  useEffect(() => {
    if (isSubnavVisible && hasSubnav) {
      click();
    }
  }, [isSubnavVisible, hasSubnav]);

  const El = useMemo(() => (href ? "a" : "button"), [href]);
  const AnimationWrapper = useMemo(
    () => (isMobile ? "div" : AnimatePresence),
    [isMobile],
  );

  return (
    <li
      className={twMerge(
        "group relative max-md:flex max-md:flex-col max-md:items-center max-md:py-2",
        hasSubnav && "has-subnav",
      )}
      onPointerEnter={() => setIsSubnavVisible(true)}
      onPointerLeave={() => setIsSubnavVisible(false)}
      onFocus={() => setIsSubnavVisible(true)}
      onBlur={() => setIsSubnavVisible(false)}
    >
      <El
        className={twMerge(
          "relative flex cursor-pointer flex-row items-center px-3 leading-[1] outline-none before:absolute before:-bottom-2 before:-left-1 before:-right-1 before:-top-2 after:absolute after:-bottom-px after:left-2 after:right-2 after:block after:h-[1px] after:bg-white after:transition-[transform,opacity] max-md:py-4 max-md:text-center max-md:before:hidden max-md:after:hidden md:h-5 [&:not(.active)]:after:translate-y-2 [&:not(.active)]:after:opacity-0 group-focus-within:[&:not(.active)]:after:translate-y-0 group-focus-within:[&:not(.active)]:after:opacity-100 group-hover:[&:not(.active)]:after:translate-y-0 group-hover:[&:not(.active)]:after:opacity-100 [&:not([href])]:after:hidden",
          href && activePath && activePath.startsWith(href) && "active",
        )}
        href={href}
      >
        {title}

        {!href && (
          <ChevronDown className="transition-transform group-focus-within:translate-y-[2px] group-hover:translate-y-[2px] max-md:hidden" />
        )}
      </El>

      <motion.div
        className={twMerge(
          "md:pointer-events-[--pointerEvents] md:opacity-[--opacity]",
          hasSubnav && "submenu",
        )}
        variants={
          {
            "no-hover": { "--opacity": 0, "--pointerEvents": "none" },
            hover: { "--opacity": 1, "--pointerEvents": "auto" },
          } as Variants
        }
      >
        <AnimationWrapper>
          {subnavRendered && (
            <motion.div
              layout={!isMobile}
              className="pointer-events-none text-white group-hover:pointer-events-auto max-md:pb-4 md:absolute md:left-1/2 md:top-full md:-translate-x-1/2 md:pt-4"
            >
              <motion.span
                className="absolute left-0 top-0 block h-4 w-full [mask-image:linear-gradient(to_top,black_50%,transparent_calc(50%+1px),transparent_100%)] max-md:hidden"
                layout={!isMobile}
                layoutId={isMobile ? undefined : "subnav-arrow"}
              >
                <span className="absolute left-[calc(50%-4px)] top-[10px] block h-4 w-4 rotate-45 rounded-md bg-[rgba(255,255,255,.3)]"></span>
              </motion.span>
              <motion.div
                layout={!isMobile}
                layoutId={isMobile ? undefined : "subnav-background"}
                className="relative z-10 overflow-clip rounded-2xl text-white backdrop-blur-md max-md:text-center md:bg-[rgba(255,255,255,.3)] md:p-2"
              >
                <motion.div layout={!isMobile}>
                  <motion.div
                    className="relative opacity-[--opacity] [--opacity:0] [--x:0px] [transform:translate3d(var(--x),0,0)]"
                    custom={direction}
                    transition={{
                      ease: "easeOut",
                      duration: 0.3,
                      delay: 0.2,
                    }}
                    animate="visible"
                    initial="initial"
                    exit="exit"
                    variants={
                      {
                        visible: {
                          "--x": "0px",
                          "--opacity": 1,
                        },
                        initial: (direction) => ({
                          "--x": direction === "left" ? "-30px" : "30px",
                          "--opacity": 0,
                        }),
                        exit: (direction) => ({
                          "--x": direction === "left" ? "30px" : "-30px",
                          "--opacity": 0,
                        }),
                      } as Variants
                    }
                  >
                    {children}
                  </motion.div>
                </motion.div>
              </motion.div>
            </motion.div>
          )}
        </AnimationWrapper>
      </motion.div>
    </li>
  );
};

export const Navigation = ({ children, isPro, activePath }: Props) => {
  const setDirection = useDirectionStore((state) => state.setDirection);
  const [isNavVisible, setIsNavVisible] = useState(false);
  const xRef = useRef<number | null>(null);
  const [isHoveringItemWithSubmenu, setIsHoveringItemWithSubmenu] =
    useState(false);
  const { width } = useWindowSize();
  const isMobile = Boolean(width && width < 768);

  useEffect(() => {
    if (isNavVisible) {
      document.documentElement.classList.add("overflow-hidden");
    } else {
      document.documentElement.classList.remove("overflow-hidden");
    }
  }, [isNavVisible]);

  useEffect(() => {
    const onResizeCloseNav = () => {
      setIsNavVisible(false);
    };

    window.addEventListener("resize", onResizeCloseNav);
    return () => window.removeEventListener("resize", onResizeCloseNav);
  });

  const toggleSubnav = useCallback((pointerX: number, target: HTMLElement) => {
    const xDiff = (xRef.current ?? 0) - pointerX;

    setDirection(xDiff > 0 ? "left" : "right");
    xRef.current = pointerX;
    const li = target?.tagName === "LI" ? target : target.closest("li");
    if (li?.classList.contains("has-subnav")) {
      setIsHoveringItemWithSubmenu(true);
    } else {
      setIsHoveringItemWithSubmenu(false);
    }
  }, []);

  return (
    <>
      <button
        onClick={() => setIsNavVisible(true)}
        className="ml-auto md:hidden"
      >
        <Menu />
        <span className="sr-only">Show navigation</span>
      </button>

      <nav
        className={twMerge(
          "md:text-md fixed inset-0 z-40 h-[100dvh] w-full bg-black text-xl transition-opacity max-md:overflow-auto md:pointer-events-auto md:relative md:z-0 md:ml-4 md:h-auto md:translate-x-0 md:bg-transparent md:opacity-100 lg:ml-8",
          isNavVisible
            ? "[&_li]:animate-fadeInUp translate-x-0 opacity-100 [--fade-delay:200ms] [&_li]:translate-y-2 [&_li]:opacity-0"
            : "pointer-events-none translate-x-[100vw] opacity-0",
        )}
      >
        <button
          onClick={() => setIsNavVisible(false)}
          className="absolute right-4 top-6 md:hidden"
        >
          <X />
          <span className="sr-only">Hide navigation</span>
        </button>

        <motion.ul
          animate={isHoveringItemWithSubmenu ? "hover" : "no-hover"}
          onPointerEnter={(ev) => {
            toggleSubnav(ev.clientX, ev.target as HTMLElement);
          }}
          onPointerMove={(ev) => {
            toggleSubnav(ev.clientX, ev.target as HTMLElement);
          }}
          onFocusCapture={() => {
            setIsHoveringItemWithSubmenu(true);
          }}
          onBlur={() => {
            setIsHoveringItemWithSubmenu(false);
          }}
          onPointerLeave={() => {
            setIsHoveringItemWithSubmenu(false);
            xRef.current = null;
          }}
          className="flex min-h-full w-full flex-col items-center justify-center max-md:py-8 md:flex-row md:justify-start"
        >
          <li className="py-6 md:hidden">
            <LogoIcon />
          </li>
          <MenuItem
            id="videos"
            title="Tutorials"
            href="/videos"
            activePath={activePath}
            isMobile={isMobile}
          />
          <MenuItem
            id="recipes"
            title="Recipes"
            href="/recipes"
            activePath={activePath}
            isMobile={isMobile}
          >
            {!import.meta.env.PROD && <SubnavRecipes />}
          </MenuItem>

          <MenuItem
            id="courses"
            title="Courses"
            href="/courses"
            activePath={activePath}
            isMobile={isMobile}
          >
            <SubnavCourses />
          </MenuItem>

          <MenuItem
            id="more"
            title="More"
            activePath={activePath}
            isMobile={isMobile}
          >
            <SubnavMore />
          </MenuItem>

          {!isPro && (
            <li className="md:ml-auto md:pr-3">
              <Button href="/pro" intent="white" size="small">
                Go PRO
              </Button>
            </li>
          )}
          {children}
        </motion.ul>
      </nav>
    </>
  );
};
