type Props = {
  className: string;
};

export const FramerLogo = ({ className }: Props) => (
  <svg
    role="img"
    className={className}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="currentColor" d="M4 0h16v8h-8zM4 8h8l8 8H4zM4 16h8v8z"></path>
  </svg>
);
